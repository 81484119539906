import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from './AuthContext';

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

console.log(backendServerUrl)

const Login = () => {
  const { logIn } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isHandlingClick, setIsHandlingClick] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsHandlingClick(true);
    
    axios
    .post(`${backendServerUrl}/api/user/login`, { username, password }, { withCredentials: true })
      .then((response) => {
        logIn(response.data.username, response.data.balance, response.data.userId);
        const redirectURL = sessionStorage.getItem('redirectAfterLogin') || '/';
        console.log(redirectURL);
        sessionStorage.removeItem('redirectAfterLogin'); // Cleanup session storage
        navigate(redirectURL);
        setIsHandlingClick(false);
        //alert("Logged in");
      })
      .catch((error) => {
        setIsHandlingClick(false);
        console.log(error.response)
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          alert(error.response.data); // assuming the server responds with a JSON that has a 'message' field
        } else if (error.request) {
          // The request was made but no response was received
          alert("No response received from the server");
        } else {
          // Something happened in setting up the request that triggered an Error
          alert("Error Logging in");
        }
      });
  };


  return (
    <div className="flex justify-center items-center h-screen bg-gray-200">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4">Login</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Username"
            className="w-full p-2 mb-4 border rounded"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className="w-full p-2 mb-4 border rounded"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="relative z-10">
            <button className="w-full bg-blue-500 text-white p-2 rounded" type="submit">
              Login
            </button>
            {isHandlingClick && (
              <div className="absolute inset-0 flex items-center justify-center rounded bg-blue-700 text-white">
                Loading...
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
