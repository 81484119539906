// ComingSoon.js
import React from 'react';

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Coming Soon</h1>
        <p>We are working hard to launch our website. Stay tuned!</p>
        {/* Add your countdown timer here */}
      </div>
    </div>
  );
};

export default ComingSoon;
