import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthProvider, useAuth } from '../auth/AuthContext';
import 'font-awesome/css/font-awesome.min.css';
import { BasketContext } from '../payment/BasketContext';

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const NavigationBar = () => {
  const { isLoggedIn, logOut, username, loading, balance } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { basketItems } = useContext(BasketContext);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [isNotificationDropdownOpen, setNotificationDropdownOpen] = useState(false);

  const distinctItemCount = basketItems.length;

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
    setMenuOpen(false);
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${backendServerUrl}/api/notification`, { withCredentials: true });
      setNotifications(response.data);
      const unreadCount = response.data.filter(notification => notification.state === 'new').length;
      setUnreadNotificationCount(unreadCount);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  

  const handleLogout = async () => {
      try {
      const response = await axios.post(`${backendServerUrl}/api/user/logout`, {}, { withCredentials: true });
      if (response.status === 200) {
          logOut();
          navigate('/');
      }
      } catch (error) {
      console.error('An error occurred while logging out:', error);
      }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleNotificationClick = async (notificationId, link) => {
    navigate(link);
    try {
        await axios.put(`${backendServerUrl}/api/notification/read/${notificationId}`, {}, { withCredentials: true });
    } catch (error) {
        console.error('Error marking notification as read:', error);
    }
    setNotificationDropdownOpen(false);
    fetchNotifications();
};


  return (
    <AuthProvider>
      <nav className="relative bg-gradient-to-t from-cyan-500 via-sky-600 to-blue-700 p-4">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            
            {/* Logo or Brand Name */}
            <div className="text-white text-2xl font-mono">
              <Link to="/">
                <img 
                  src="header_logo.png" 
                  alt="Prize Trust" 
                  className="w-auto h-6"
                />
              </Link>
            </div>

            {/* Hamburger menu and Basket icon for small screens */}
            <div className="flex md:hidden items-center">
              {/* Basket with notification */}
              <div className="relative inline-flex mr-2">
                <Link to="/basket">
                  <i className="fa fa-shopping-basket text-white cursor-pointer" style={{fontSize: "2rem"}}></i>
                </Link>
                {distinctItemCount > 0 && (
                  <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                    {distinctItemCount}
                  </span>
                )}
              </div>
              {/* Notification Bell */}
              <div className="relative inline-flex mx-2">
                <i 
                  className="fa fa-bell text-white cursor-pointer" 
                  style={{fontSize: "2rem"}}
                  onClick={() => setNotificationDropdownOpen(!isNotificationDropdownOpen)}
                ></i>
                {unreadNotificationCount > 0 && (
                  <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                    {unreadNotificationCount}
                  </span>
                )}
                {isNotificationDropdownOpen && (
                  <div className="absolute right-0 mt-10 w-64 bg-white border rounded shadow-lg z-50">
                    {notifications.map(notification => (
                      <div 
                        key={notification._id} 
                        className={`p-2 cursor-pointer ${notification.state === 'new' ? 'bg-blue-100' : ''}`}
                        onClick={() => handleNotificationClick(notification._id, notification.link)}
                      >
                        {notification.message}
                        <span className="block text-sm text-gray-500">
                          {new Date(notification.date).toLocaleString()}
                        </span>
                      </div>
                    ))}
                    {notifications.length === 0 && (
                      <div className="p-2 text-center text-gray-500">
                        You have no new notifications
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* Hamburger menu */}
              <button className="text-white ml-2" style={{fontSize: "2rem"}} onClick={() => setMenuOpen(!menuOpen)}>
                ☰
              </button>
            </div>

            
            {/* Search Bar and Button (Hidden on small screens, visible on medium and up) */}
            <form onSubmit={handleSearch} className="hidden md:flex items-center space-x-2 mx-auto w-1/2">
              <input 
                type="text" 
                value={searchQuery} 
                onChange={e => setSearchQuery(e.target.value)} 
                className="p-2 rounded focus:outline-none focus:border-gray-700 w-full"
              />
              <button 
                onClick={handleSearch} 
                className="bg-green-500 text-white p-2 rounded hover:bg-green-600 focus:outline-none"
              >
                Search
              </button>
            </form>

            {/* Authentication Buttons (Hidden on small screens, visible on medium and up) */}
            <div className="hidden md:flex space-x-4">
            {isLoggedIn ? (
                <div className="flex items-center">
                   {/* Basket Icon and Notification */}
                  <Link  to={`/profile/${username}`}>
                    <div className="flex flex-col text-white hover:bg-blue-950 rounded-md px-1 pb-1">
                      <p>Hello, {username}</p>
                      <span>Balance: £{(balance / 100).toFixed(2)}</span>
                    </div>
                  </Link>
                  <div className="relative inline-flex md:mx-2">
                    <Link to="/basket">
                      <i className="fa fa-shopping-basket text-white cursor-pointer" style={{fontSize: "2rem"}}></i>
                    </Link>
                    {distinctItemCount > 0 && (
                      <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                        {distinctItemCount}
                      </span>
                    )}
                  </div>
                  <div className="relative inline-flex md:mx-2">
                    <i 
                      className="fa fa-bell text-white cursor-pointer" 
                      style={{fontSize: "2rem"}}
                      onClick={() => setNotificationDropdownOpen(!isNotificationDropdownOpen)}
                    ></i>
                    {unreadNotificationCount > 0 && (
                      <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                        {unreadNotificationCount}
                      </span>
                    )}
                    {isNotificationDropdownOpen && (
                      <div className="absolute right-0 mt-10 w-64 bg-white border rounded shadow-lg z-50">
                        {notifications.map(notification => (
                          <div 
                            key={notification._id} 
                            className={`p-2 cursor-pointer ${notification.state === 'new' ? 'bg-blue-100' : ''}`}
                            onClick={() => handleNotificationClick(notification._id, notification.link)}
                          >
                            {notification.message}
                            <span className="block text-sm text-gray-500">
                              {new Date(notification.date).toLocaleString()}
                            </span>
                          </div>
                        ))}
                        {notifications.length === 0 && (
                          <div className="p-2 text-center text-gray-500">
                            You have no new notifications
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <button onClick={handleLogout} className="text-white bg-red-500 hover:bg-red-700 rounded py-2 px-4 ml-2">
                    Logout
                  </button>
                </div>
              ) : (
                <>
                  <Link to="/login" className="text-white bg-green-500 hover:bg-green-700 rounded py-2 px-4">
                    Login
                  </Link>
                  <Link to="/register" className="text-white bg-yellow-500 hover:bg-yellow-700 rounded py-2 px-4">
                    Register
                  </Link>
                </>
              )}
            </div>
          </div>

          {/* Dropdown menu on small screens */}
          <div className={`absolute top-full right-0 mt-0 md:mt-12 space-y-4 z-40 md:hidden bg-gradient-to-b from-cyan-500 via-sky-600 to-blue-400 p-4 rounded-b-lg transform transition-transform duration-300 ${menuOpen ? 'scale-y-100' : 'scale-y-0'} origin-top`}>
              
            {/* User Authentication Section */}
            <div className="space-y-2 border-b-2 border-white pb-4">
              {isLoggedIn ? (
                <div className="flex flex-col items-center space-y-2">
                  <span className="text-white text-lg">{'Hello, '}
                    <Link to={`/profile/${username}`}>
                      {username}
                    </Link>
                  </span>
                  <span className="text-white">Balance: £{(balance / 100).toFixed(2)}</span>
                  <button 
                    onClick={() => { handleLogout(); setMenuOpen(false); }} 
                    className="text-white text-center bg-red-500 hover:bg-red-700 rounded py-2 px-4 w-full">
                    Logout
                  </button>
                </div>
              ) : (
                <div className="flex flex-col items-center space-y-2">
                  <Link 
                    to="/login" 
                    onClick={() => setMenuOpen(false)} 
                    className="text-white text-center bg-green-500 hover:bg-green-700 rounded py-2 px-4 w-full">
                    Login
                  </Link>
                  <Link 
                    to="/register" 
                    onClick={() => setMenuOpen(false)} 
                    className="text-white text-center bg-yellow-500 hover:bg-yellow-700 rounded py-2 px-4 w-full">
                    Register
                  </Link>
                </div>
              )}
            </div>

            {/* Search Bar and Button */}
            <div className="space-y-2pb-4">
              <span className="text-white block text-center mb-2">Search for Raffles</span>
              <form onSubmit={handleSearch} className="flex flex-col items-center space-y-2">
                <input 
                  type="text"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  className="p-2 rounded focus:outline-none focus:border-gray-700 w-full"
                />
                <button
                  type="submit"
                  //onClick={() => {setMenuOpen(false); }}
                  className="bg-green-500 text-white p-2 rounded hover:bg-green-600 focus:outline-none w-full"
                >
                  Search
                </button>
              </form>
            </div>

            {/* Create Raffle and My Raffles Buttons */}
            {isLoggedIn && ( <div className="flex flex-col items-center space-y-2 border-t-2 pt-4 border-white">
              <Link 
                to="/create-raffle" 
                onClick={() => setMenuOpen(false)} 
                className="text-white bg-purple-600 hover:bg-purple-700 rounded py-2 px-4 w-full text-center">
                Create Raffle
              </Link>
              <Link 
                to="/my-raffles" 
                onClick={() => setMenuOpen(false)} 
                className="text-white bg-purple-600 hover:bg-purple-700 rounded py-2 px-4 w-full text-center">
                My Raffles
              </Link>
              <Link 
                to="/my-entries" 
                onClick={() => setMenuOpen(false)} 
                className="text-white bg-purple-600 hover:bg-purple-700 rounded py-2 px-4 w-full text-center">
                My Entries
              </Link>
            </div>)}
          </div>


          {/* Secondary Nav for other Links */}
          <div className="mt-2 space-x-4 hidden md:inline">
            <Link to="/" className="text-white hover:text-gray-300">
              Home
            </Link>
            <Link to="/create-raffle" className="text-white hover:text-gray-300">
              Create Raffle
            </Link>
            <Link to="/my-raffles" className="text-white hover:text-gray-300">
              My Raffles
            </Link>
            <Link to="/my-entries" className="text-white hover:text-gray-300">
              My Entries
            </Link>
          </div>
        </div>
      </nav>
    </AuthProvider>

  );
};

export default NavigationBar;
