import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-4">Contact Us</h1>
      <p>Email: info@prizetrust.co.uk</p>
      {/*<p>Phone: +44 123 456 789</p>*/}
      {/* More content here */}
    </div>
  );
};

export default ContactUs;
