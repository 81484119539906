import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const MyRaffles = () => {
  const [raffles, setRaffles] = useState([]);
  const { isLoggedIn, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch raffles created by the user
    const fetchMyRaffles = async () => {
      try {
        const res = await axios.get(`${backendServerUrl}/api/raffle/myraffles`, { withCredentials: true });
        setRaffles(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchMyRaffles();
  }, []);

  useEffect(() => {
    if (!loading && !isLoggedIn) {
      sessionStorage.setItem('redirectAfterLogin', '/create-raffle');
      navigate('/login');
    }
  }, [loading, isLoggedIn, navigate]);

  if (loading) {
    return (
    <div className="flex justify-center items-center">
      <div className="spinner m-4"></div>
    </div> )
  }

  if (isLoggedIn){
    return (
      <div className="container mx-auto p-4 space-y-4">
        <h1 className="text-4xl font-bold mb-4">My Raffles</h1>
  
        {['Active', 'Closed', 'Drawn'].map(status => (
          <div className="mb-8" key={status}>
            <h2 className="text-2xl font-semibold mb-2">{`${status} Raffles`}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {raffles.filter(raffle => raffle.state === status).map((raffle, index) => (
                <div key={index} className="bg-white p-4 rounded shadow">
                  <Link to={`/raffles/${raffle._id}`} className="text-xl font-bold hover:text-blue-600">
                    {raffle.title}
                  </Link>
                  <div>
                    <img 
                      src={raffle.imageUrls && raffle.imageUrls.length > 0 ? raffle.imageUrls[0] : 'default-image.jpg'} 
                      className="w-full mt-2 rounded"
                    />
                  </div>
                  <div className="mt-2">
                    {/* Additional raffle details */}
                    <p>{`Description: ${raffle.description}`}</p>
                    <p>{`Ticket Price: £${new Number(raffle.ticketPrice).toFixed(2)}`}</p>
                    <p>{`Tickets sold: ${raffle.ticketsSold}`}</p>
                    <p>{`Ends: ${new Date(raffle.endDate).toLocaleString('en-GB')}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };
}
  
export default MyRaffles;
