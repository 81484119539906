import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-4">About Us</h1>
      <p>
        We are PrizeTrust, your go-to platform for exciting and fair raffles...
      </p>
      {/* More content here */}
    </div>
  );
};

export default AboutUs;
