import React from 'react';
import { useBasket } from './BasketContext';
import { Link } from 'react-router-dom';

const BasketPage = () => {
  const { basketItems, removeFromBasket, updateQuantity, totalAmount } = useBasket();

  return (
    <div className="bg-prize-white text-prize-blue min-h-screen p-8">
      <h1 className="text-2xl font-bold mb-4">Your Basket</h1>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {basketItems.map((item, index) => (
          <div key={index} className="bg-white border rounded-lg p-4 shadow-md">
            <span className="text-lg">{item.title}</span>
            <div className="flex items-center mt-2">
              <input
                type="number"
                value={item.quantity}
                onChange={(e) => updateQuantity(item.id, parseInt(e.target.value))}
                className="border rounded-md px-2 py-1 w-1/3"
              />
              <button 
                onClick={() => removeFromBasket(item.id)}
                className="ml-2 text-red-500"
              >
                Remove
              </button>
            </div>
            <div className="text-xl mt-2">
              £{item.price} x {item.quantity} = £{(item.price * item.quantity).toFixed(2)}
            </div>
          </div>
        ))}
      </div>
      <div className="font-bold text-xl mt-4">
        Total: £{totalAmount.toFixed(2)}
      </div>
      <Link to="/checkout" className="bg-prize-blue text-white py-2 px-4 rounded mt-4 inline-block">
        Checkout
      </Link>
    </div>
  );
};

export default BasketPage;
