import React, { useState } from 'react';

const ChangeDetails = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');

  const handleSave = () => {
    // Logic to save changes goes here (e.g., API call to update user details)

    onClose();  // Close the modal after saving
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="text-2xl mb-4">Change Details</h2>

        <label className="block mb-2">
          Email:
          <input 
            type="email" 
            value={email} 
            onChange={e => setEmail(e.target.value)} 
            className="border rounded w-full py-1 px-2" 
          />
        </label>

        <label className="block mb-2">
          Password:
          <input 
            type="password" 
            value={password} 
            onChange={e => setPassword(e.target.value)} 
            className="border rounded w-full py-1 px-2" 
          />
        </label>

        <label className="block mb-4">
          Address:
          <textarea 
            value={address} 
            onChange={e => setAddress(e.target.value)} 
            className="border rounded w-full py-1 px-2" 
          ></textarea>
        </label>

        <button onClick={handleSave} className="py-2 px-4 bg-green-500 text-white rounded mr-2">
          Save
        </button>
        <button onClick={onClose} className="py-2 px-4 bg-red-500 text-white rounded">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ChangeDetails;
