import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-cyan-500 via-sky-600 to-blue-700 p-4 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1">
            <h3 className="text-xl font-semibold mb-2">About Us</h3>
            <ul className="space-y-2">
              <li><Link to="/about">Who We Are</Link></li>
            </ul>
          </div>
          <div className="col-span-1">
            <h3 className="text-xl font-semibold mb-2">Support</h3>
            <ul className="space-y-2">
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
          <div className="col-span-1">
            <h3 className="text-xl font-semibold mb-2">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/terms-of-service">Terms & Conditions</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="col-span-1">
            <h3 className="text-xl font-semibold mb-2">Follow Us</h3>
            <div className="grid grid-cols-2 gap-2">
              <div><a href="https://www.facebook.com/prizetrust" target="_blank" rel="noopener noreferrer">Facebook</a></div>
              <div><a href="https://www.twitter.com/prizetrust" target="_blank" rel="noopener noreferrer">Twitter</a></div>
              <div><a href="https://www.instagram.com/prizetrust" target="_blank" rel="noopener noreferrer">Instagram</a></div>
              <div><a href="https://www.tiktok.com/@prizetrust" target="_blank" rel="noopener noreferrer">TikTok</a></div>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p>&copy; {new Date().getFullYear()} PrizeTrust. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
