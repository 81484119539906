import React from 'react';

const FAQ = () => {
  const questionsAnswers = [
    {
      question: 'How does Prize Trust work?',
      answer: 'Prize Trust is an online raffle platform where users can participate in or host raffles.',
    },
    {
      question: 'Is Prize Trust secure?',
      answer: 'Yes, we use state-of-the-art encryption and security measures to ensure your data and transactions are safe.',
    },
    {
      question: 'How can I participate in a raffle?',
      answer: 'You can participate by purchasing a ticket for the raffle of your choice.',
    },
    {
      question: 'How do I host a raffle?',
      answer: 'You can host a raffle by clicking on the "Create Raffle" button and following the instructions.',
    },
    {
      question: 'What is the ticket price?',
      answer: 'The ticket price varies depending on the raffle. The host sets the ticket price.',
    },
  ];

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Frequently Asked Questions</h1>
      <ul>
        {questionsAnswers.map((qa, index) => (
          <li key={index} className="mb-4">
            <h2 className="text-xl font-semibold">{qa.question}</h2>
            <p>{qa.answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
