import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy for Prize Trust</h1>
      
      <p><strong>Last updated: [Date]</strong></p>

      <h2 className="text-2xl font-semibold mt-4">Introduction</h2>
      <p>At Prize Trust, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and safeguard your personal information. By using our website, you agree to the terms and conditions laid out in this Privacy Policy.</p>

      <h2 className="text-2xl font-semibold mt-4">What Information We Collect</h2>
      <p>We collect information that helps us provide you with the best possible experience. The information we collect includes:</p>
      <ul>
        <li>Username</li>
        <li>Email Address</li>
        <li>Encrypted Password</li>
        <li>Account Balance</li>
        <li>User Preferences</li>
        <li>A delivery address, provided by you, for passing on to the Raffle host</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-4">How We Use Your Information</h2>
      <p>We use your data for the following purposes:</p>
      <ul>
        <li>To manage your account and provide you with our services</li>
        <li>Analytics to help us understand how users engage with our site</li>
        <li>To improve our services based on user interaction and feedback</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-4">Sharing of Information</h2>
      <p>We will not share your personal information with third parties for advertising or analytics.</p>

      <h2 className="text-2xl font-semibold mt-4">Data Storage and Security</h2>
      <p>Your information is securely stored. We use encryption techniques to secure your passwords. Additionally, we implement security protocols and practices to ensure the safeguarding of your data.</p>

      <h2 className="text-2xl font-semibold mt-4">Your Rights</h2>
      <p>You have the right to remove your account from Prize Trust, provided there are no outstanding raffles.</p>

      <h2 className="text-2xl font-semibold mt-4">Cookies</h2>
      <p>We use cookies to keep track of your session, which helps in keeping you logged in while using our website.</p>

      <h2 className="text-2xl font-semibold mt-4">Children's Privacy</h2>
      <p>Our website is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If you are under 13, please do not use Prize Trust or send any information about yourself to us.</p>

      <h2 className="text-2xl font-semibold mt-4">Changes to this Policy</h2>
      <p>We may update this Privacy Policy as required. All changes will be communicated to you via email.</p>

      <h2 className="text-2xl font-semibold mt-4">Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, you can contact us via email.</p>
    </div>
  );
};

export default PrivacyPolicy;
