import React, { useState, useEffect } from 'react';
import axios from 'axios';

const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const VerifyEmail = () => {
    const [status, setStatus] = useState('verifying');
    const [message, setMessage] = useState('Verifying your email...');

    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get('token');

        axios.get(`${backendServerUrl}/api/user/verifyemail?token=${token}`)
            .then(response => {
                setStatus('success');
                setMessage('Email verified successfully! You can now log in.');
            })
            .catch(error => {
                setStatus('error');
                setMessage('Failed to verify email. The link may be expired or invalid.');
            });
    }, []);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-96">
                <h2 className="text-2xl text-center font-bold mb-4">
                    Email Verification
                </h2>
                <div className={`text-center ${status === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                    {message}
                </div>
            </div>
        </div>
    );
}

export default VerifyEmail;