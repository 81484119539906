import React, { useState, useRef, useEffect } from 'react';

const DeliveryAddressForm = ({ setDeliveryAddr, addressType }) => {
  const addressInputRef = useRef(null);
  //const [showDetailedFields, setShowDetailedFields] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState({
    houseNumber: '',
    streetName: '',
    city: '',
    county: '',
    postcode: '',
    country: ''
  });
  const [enterDeliveryManually, setEnterDeliveryManually] = useState(false);

  //const placesService = new window.google.maps.places.PlacesService(addressInputRef.current);

  const handleAddressSelection = (place) => {
    const addressComponents = {};
    console.log(place.address_components);
    for (const component of place.address_components) {
      switch (component.types[0]) {
        case 'street_number':
          addressComponents.houseNumber = component.long_name;
          break;
        case 'route':
          addressComponents.streetName = component.long_name;
          break;
        case 'locality':
          addressComponents.city = component.long_name;
          break;
        case 'administrative_area_level_2':
          addressComponents.county = component.short_name;
          break;
        case 'postal_code':
          addressComponents.postcode = component.long_name;
          break;
        case 'country':
          addressComponents.country = component.long_name;
          break;
        default:
          break;
      }
    }
    setDeliveryAddress(addressComponents);
    // console.log(addressComponents);
    // setShowDetailedFields(true);
  };

  useEffect(() => {
    if (!enterDeliveryManually) {
      const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
        types: ['address'],
        componentRestrictions: { country: 'uk' }
      });
      autocomplete.setFields(['address_components', 'formatted_address']);
      autocomplete.addListener('place_changed', () => {
        console.log('place_changed event triggered');
        const place = autocomplete.getPlace();
        console.log(place);
        if (place) {
          handleAddressSelection(place);
        }
      });
    }
  }, [enterDeliveryManually]);

  const handleEnterManuallyClick = () => {
    setEnterDeliveryManually(true);
    setDeliveryAddress(deliveryAddress);
  };


  return (
    <div>
      <h2 className="text-2xl mb-4">{addressType} Address</h2>
      <form onSubmit={(e) => {
        e.preventDefault();
        setDeliveryAddress(deliveryAddress);
      }}>
        {!enterDeliveryManually && (
          <>
            <div className="mb-4">
              <input 
                ref={addressInputRef}
                placeholder='Start typing your address...'
                id="address"
                type="text" 
                className="border rounded w-full py-2 px-3"
                required
              />
            </div>
            <button type="button" onClick={handleEnterManuallyClick} className="bg-gray-500 text-white py-2 px-4 mr-2 rounded mb-4">
              Enter Manually
            </button>
          </>
        )}
        {enterDeliveryManually && (
          <>
            <div className="mb-4">
              <label htmlFor="houseNumber" className="block mb-2">Building Number or Name:</label>
              <input 
                value={deliveryAddress.houseNumber}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, houseNumber: e.target.value })}
                id="houseNumber"
                type="text" 
                className="border rounded w-full py-2 px-3"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="streetName" className="block mb-2">Street Name:</label>
              <input 
                value={deliveryAddress.streetName}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, streetName: e.target.value })}
                id="streetName"
                type="text" 
                className="border rounded w-full py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="city" className="block mb-2">Town/City:</label>
              <input 
                value={deliveryAddress.city}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, city: e.target.value })}
                id="city"
                type="text" 
                className="border rounded w-full py-2 px-3"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="county" className="block mb-2">County:</label>
              <input 
                value={deliveryAddress.county}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, county: e.target.value })}
                id="county"
                type="text" 
                className="border rounded w-full py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="postcode" className="block mb-2">Postcode:</label>
              <input 
                value={deliveryAddress.postcode}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, postcode: e.target.value })}
                id="postcode"
                type="text" 
                className="border rounded w-full py-2 px-3"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="country" className="block mb-2">Country:</label>
              <input 
                value={deliveryAddress.country}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, country: e.target.value })}
                id="country"
                type="text" 
                className="border rounded w-full py-2 px-3"
                required
              />
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default DeliveryAddressForm;
