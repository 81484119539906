import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-4">Terms of Service</h1>
      <p>
        By using PrizeTrust, you agree to comply with the following terms...
      </p>
      {/* More content here */}
    </div>
  );
};

export default TermsOfService;
