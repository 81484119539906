import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const MyEntries = () => {
  const [enteredRaffles, setEnteredRaffles] = useState([]);
  const { isLoggedIn, loading, userId } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMyEntriesAndWins = async () => {
      try {
        // Fetch raffles that the user has entered
        const entriesRes = await axios.get(`${backendServerUrl}/api/raffle/myentries`, { withCredentials: true });
        setEnteredRaffles(entriesRes.data);
    
        // Fetch raffles that the user has won
        const winsRes = await axios.get(`${backendServerUrl}/api/raffle/myentries?isWinner=true`, { withCredentials: true });
        // Handle the winsRes data as required
    
      } catch (err) {
        console.error(err);
      }
    };
    

    fetchMyEntriesAndWins();
  }, []);

  useEffect(() => {
    if (!loading && !isLoggedIn) {
      sessionStorage.setItem('redirectAfterLogin', '/create-raffle');
      navigate('/login');
    }
  }, [loading, isLoggedIn, navigate]);

  if (loading) {
    return (
    <div className="flex justify-center items-center">
      <div className="spinner m-4"></div>
    </div> )
  }

  if (isLoggedIn){
    return (
      <div className="container mx-auto p-4 space-y-4">
        <h1 className="text-4xl font-bold mb-4">My Entries</h1>

        {['Active', 'Closed', 'Drawn'].map(status => {
          const filteredRaffles = enteredRaffles.filter(raffle => raffle.raffleDetails.state === status);
          return (
            filteredRaffles.length > 0 && (
              <div className="mb-8" key={status}>
                <h2 className="text-2xl font-semibold mb-2">{`${status} Raffles`}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {filteredRaffles.map((raffle, index) => (
                    <div key={index} className="bg-white p-4 rounded shadow">
                      <Link to={`/entry-details/${raffle.raffleDetails._id}`} className="text-xl font-bold hover:text-blue-600">
                        {raffle.raffleDetails.title}
                      </Link>
                      <div>
                        <Link to={`/profile/${raffle.raffleDetails.host}`} className="hover:text-blue-600">
                          by {raffle.raffleDetails.host}
                        </Link>
                      </div>
                      <div>
                        <img 
                          src={raffle.raffleDetails.imageUrls && raffle.raffleDetails.imageUrls.length > 0 ? raffle.raffleDetails.imageUrls[0] : 'default-image.jpg'}
                          className="w-full mt-2 rounded"
                        />
                      </div>
                      <div className="mt-2">
                        {/* Additional raffle details */}
                        <b>{`Ends: ${new Date(raffle.raffleDetails.endDate).toLocaleString('en-GB')}`}</b>
                        <p>{`Description: ${raffle.raffleDetails.description}`}</p>
                        <p>{`Ticket Price: £${new Number(raffle.raffleDetails.ticketPrice).toFixed(2)}`}</p>
                        <p>{`Tickets entered: ${raffle.quantity}`}</p> {/* Display quantity of entries */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };
};

export default MyEntries;
