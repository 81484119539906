import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import OptionSelectionPage from './OptionSelectionPage';
import { useAuth } from '../auth/AuthContext';

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const RaffleDetails = () => {  
  const navigate = useNavigate();
  const location = useLocation();
  const [raffle, setRaffle] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { id } = useParams(); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isHandlingClick, setIsHandlingClick] = useState(false);
  const { userId: authUserId } = useAuth();
  const [userId , setUserId] = useState(authUserId || ""); 
  const paypalRef = useRef();



  useEffect(() => {
      const fetchRaffle = async () => {
      try {
          const response = await axios.get(`${backendServerUrl}/api/raffle/${id}`);
          setRaffle(response.data);
      } catch (error) {
          console.error('An error occurred while fetching data: ', error);
      }
  };

    fetchRaffle();
  }, [id]);

  const closeOptionsModal = () => {
    setIsOptionModalOpen(false);
  };

  if (!raffle) {
    return (
      <div className="flex justify-center items-center">
        <div className="spinner m-4"></div>
      </div> );
  }

  const handleImageClick = (url) => {
    setSelectedImageIndex(raffle.imageUrls.indexOf(url));
    setIsModalOpen(true);
  };

  const handleNextImage = () => {
    const nextIndex = (selectedImageIndex + 1) % raffle.imageUrls.length;
    setSelectedImageIndex(nextIndex);
  };

  const handlePrevImage = () => {
    const prevIndex = (selectedImageIndex - 1 + raffle.imageUrls.length) % raffle.imageUrls.length;
    setSelectedImageIndex(prevIndex);
  };
  
  const checkTicketLimit = async (raffleId) => {
    try {
      const response = await axios.get(`${backendServerUrl}/api/raffle/userticketcount`, {
        params: {
          raffleId: raffleId
        },
        withCredentials: true
      });
      
        if (response.status === 200) {
            const totalTickets = response.data.totalTickets;

            if (totalTickets >= raffle.ticketLimit) {
                alert("You have reached the ticket limit for this raffle.");
                return false; // Prevent adding to basket
            } else {
                return true; // Allow adding to basket
            }
        } else if (response.status === 404) {
            // No entries found for this user, so they can add to basket
            return true;
        }
    } catch (error) {
        console.error("Error checking ticket limit:", error);
        alert("An error occurred while checking the ticket limit. Please try again.");
        return false; // Prevent adding to basket due to error
    }
  };


  const handleEnterRaffle = async () => {
    setIsHandlingClick(true);

    const spacesLeft = raffle.maxTickets - raffle.ticketsSold;

    const canAddToBasket = await checkTicketLimit(raffle._id);

    if (!canAddToBasket) {
      alert(`This Raffle has a limit of ${raffle.ticketLimitPerParticipant} tickets per user.`);
    }
    //console.log(raffle);
    else if (raffle.maxTickets === -1) {
      setIsOptionModalOpen(true);
      setIsHandlingClick(false);
    }
    else if (spacesLeft === 0){
      alert(`This raffle is full!`);
    }
    else if (quantity > spacesLeft) 
    {
      alert(`This raffle only has ${spacesLeft} spaces left!`);
    }
    else {
      setIsOptionModalOpen(true);
      setIsHandlingClick(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-5">
      <h2 className="text-4xl mb-8 font-semibold text-center">Enter Raffle</h2>
      <div className="bg-white p-8 rounded-lg shadow-md w-full md:w-3/4 lg:w-1/2 mx-auto">
        <h1 className="text-3xl font-semibold">{raffle.title}</h1>
        <div className="mb-6">
          <Link to={`/profile/${raffle.host}`} className="hover:text-blue-600">
            by {raffle.host}
          </Link>
        </div>
        <p className="text-lg mb-3 text-gray-700">{raffle.description}</p>
        <div className="flex space-x-4 overflow-x-auto bg-gray-300 p-2 rounded">
          {raffle.imageUrls.map((url, i) => (
            <img
              key={i}
              src={url}
              alt={`Uploaded ${i}`}
              className="w-32 h-32 object-cover cursor-pointer"
              onClick={() => handleImageClick(url)}
            />
          ))}
        </div>
        <p className="text-lg font-bold mb-2 mt-2 text-gray-700">Ends: {new Date(raffle.endDate).toLocaleString('en-GB')}</p>
        <p className="text-lg mb-3 text-gray-700">
          Ticket Price: {raffle.ticketPrice === 0 ? 'Free' : `£${new Number(raffle.ticketPrice).toFixed(2)}`}
        </p>
        <p className="text-lg mb-3 text-gray-700">
          Max Tickets: {raffle.maxTickets === -1 ? 'Unlimited' : raffle.maxTickets}
        </p>
        <div className="flex items-center mb-6">
          <label htmlFor="quantity" className="mr-2 text-lg text-gray-700">Quantity:</label>
          <input
            id="quantity"
            type="number"
            min="1"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            className="p-2 border rounded-lg"
          />
        </div>
        <div className="relative inline-block">
          <button
            onClick={handleEnterRaffle}
            className={`bg-blue-500 ${userId.toString() !== raffle.owner.toString() ? 'hover:bg-blue-700' : 'bg-blue-700'} text-white font-bold py-2 px-4 rounded`}
            disabled={userId.toString() === raffle.owner.toString()}
            title={userId.toString() === raffle.owner.toString() ? "You cannot enter your own raffle." : ""}
          >
            Enter Raffle
          </button>
          {isHandlingClick && (
            <div className="absolute inset-0 flex items-center justify-center rounded bg-blue-700 text-white">
              Loading...
            </div>
          )}
          <div ref={paypalRef}></div>
        </div>
      </div>
      {/* Image Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded p-4 relative">
            <img src={raffle.imageUrls[selectedImageIndex]} alt="Enlarged" className="object-cover max-w-md max-h-96" />

            <button
              className="absolute top-1/2 left-2 text-white bg-black bg-opacity-40 hover:bg-opacity-60 rounded-full p-2"
              onClick={handlePrevImage}
            >
              <span className="text-3xl">&lt;</span>
            </button>

            <button
              className="absolute top-1/2 right-2 text-white bg-black bg-opacity-40 hover:bg-opacity-60 rounded-full p-2"
              onClick={handleNextImage}
            >
              <span className="text-3xl">&gt;</span>
            </button>

            <button
              className="text-white bg-red-500 hover:bg-red-700 rounded py-2 px-4 mt-4 block"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Option Selection Modal */}
      {isOptionModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded p-4 relative max-w-lg max-h-96 mx-auto overflow-y-auto shadow-md">
            <button 
                className="text-red-500 absolute top-2 right-2"
                onClick={() => setIsOptionModalOpen(false)}
            >
                <span className="text-2xl font-bold">&times;</span>
            </button>
            {/* Pass quantity and raffle as props */}
            <OptionSelectionPage closeModal={closeOptionsModal} quantity={quantity} raffle={raffle} />
          </div>
        </div>
      )}


    </div>
  );
};

export default RaffleDetails;
