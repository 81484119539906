import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

function Register() {
  
  const [formData, setFormData] = useState({
    username: '',
    emailAddress: '',
    password: '',
    confirmPassword: ''
  });

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [errors, setErrors] = useState({});
  const [isHandlingClick, setIsHandlingClick] = useState(false);
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    const validationErrors = validatePassword(newPassword);

    setPasswordErrors(validationErrors);
    setFormData({
      ...formData,
      password: newPassword,
    });
  };
  
  const validatePassword = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>\-_=+\/\\\[\]`~£€]/.test(password);
    const hasMinLength = password.length >= 8;
  
    const errorMsgs = [];
  
    if (!hasUppercase) {
      errorMsgs.push('Must contain at least one uppercase letter.');
    }
    if (!hasLowercase) {
      errorMsgs.push('Must contain at least one lowercase letter.');
    }
    if (!hasNumber) {
      errorMsgs.push('Must contain at least one number.');
    }
    if (!hasSpecialChar) {
      errorMsgs.push('Must contain at least one special character.');
    }
    if (!hasMinLength) {
      errorMsgs.push('Must be at least 8 characters long.');
    }
  
    return errorMsgs;
  };

  const validateField = (name, value) => {
    let errorMsg = '';

    if (name === 'username' && value.length < 5) {
      errorMsg = 'Username must be at least 5 characters';
    } else if (name === 'emailAddress' && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      errorMsg = 'Email Address is not valid';
    } else if (name === 'confirmPassword' && value !== formData.password) {
      errorMsg = 'Passwords must match';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    validateField(name, value);
  };

  // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsHandlingClick(true);

    // Check for frontend validation errors
    const hasErrors = Object.values(errors).some(errorMsg => errorMsg) || passwordErrors.length > 0;

    if (hasErrors) {
      alert("Please fix the errors before trying to Register.");
      setIsHandlingClick(false);
      return; // Exit early from the function
    }

    axios
    .post(`${backendServerUrl}/api/user/register`, formData, { withCredentials: true })
      .then((response) => {
        alert("User registered");
        setIsHandlingClick(false);
        navigate('/login');
      })
      .catch((error) => {
        setIsHandlingClick(false);
        console.log(error);  // Log the error object for inspection

        if (error.response && error.response.data) {
          // if (error.response.data.errors) {
          //   setErrors(error.response.data.errors || {});
          // }
          if (error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert("An error occurred during registration.");
          }
        } else if (error.message) {
          alert(error.message);
        } else {
          alert("An unknown error occurred during registration.");
        }
      });
  };


  return (
    <div className="flex justify-center items-center h-screen bg-gray-200">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4">Register</h2>
        <p className="mb-4">
          Welcome to PrizeTrust, where we prioritize safety and trustworthiness. Our mission is to provide a reliable and secure platform for all your raffle needs.
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            className="w-full p-2 mb-4 border rounded"
            // value={username}
            onChange={handleChange}
          />
          <div className={`error-messages text-red-500 ${errors?.username ? 'mt-0 mb-4' : ''}`}>
          {errors?.username && <ul className="error-list list-inside list-disc">
              <li className="text-sm">{errors.username}</li>
            </ul>}
          </div>
          <input
            type="text"
            name="emailAddress"
            placeholder="Email Address"
            className="w-full p-2 mb-4 border rounded"
            // value={emailAddress}
            onChange={handleChange}
          />
          <div className={`error-messages text-red-500 ${errors?.emailAddress ? 'mt-0 mb-4' : ''}`}>
          {errors?.emailAddress && <ul className="error-list list-inside list-disc">
              <li className="text-sm">{errors.emailAddress}</li>
            </ul>}
          </div>
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="w-full p-2 mb-4 border rounded"
            // value={password}
            onChange={handlePasswordChange}
          />
          <div className={`error-messages text-red-500 ${passwordErrors?.length > 0 ? 'mt-0 mb-4' : ''}`}>
            {passwordErrors?.length > 0 && (
              <ul className="error-list list-inside list-disc">
                {passwordErrors.map((error, index) => (
                  <li key={index} className="text-sm">{error}</li>
                ))}
              </ul>
            )}
          </div>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            className="w-full p-2 mb-4 border rounded"
            // value={confirmPassword}
            onChange={handleChange}
          />
          <div className={`error-messages text-red-500 ${errors?.confirmPassword ? 'mt-0 mb-4' : ''}`}>
          {errors?.confirmPassword && <ul className="error-list list-inside list-disc">
              <li className="text-sm">{errors.confirmPassword}</li>
            </ul>}
          </div>
          <div className="relative">
            <button className="w-full bg-blue-500 text-white p-2 rounded" type="submit">
              Register
            </button>
            {isHandlingClick && (
              <div className="absolute inset-0 flex items-center justify-center rounded bg-blue-700 text-white">
                Loading...
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
