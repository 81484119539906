import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { useBasket } from '../payment/BasketContext';

const OptionSelectionPage = ({ quantity, raffle, closeModal }) => {
    //const location = useLocation();
    const navigate = useNavigate();
    //const { quantity, raffle } = location.state;
    
    const ticketPrice = raffle.ticketPrice;
    const title = raffle.title;
    const totalPrice = quantity * ticketPrice;

    const { addToBasket } = useBasket();

    const handleAddToBasket = (checkout) => {
        const item = {
            id: raffle._id,
            title: title,
            quantity: quantity,
            price: ticketPrice,
        };
        addToBasket(item);
        if(checkout) { navigate('/checkout') }
        closeModal();
    };

    return (
        <div className="bg-prize-white text-prize-blue p-8">
            <h1 className="text-3xl font-semibold mb-6">Confirm Selection</h1>
            <h2 className="text-2xl font-medium mb-4">Raffle: {title}</h2>

            <div className="mb-4">
                <span className="text-lg font-medium">Selected Quantity: </span>
                <span className="text-lg">{quantity}</span>
            </div>

            <div className="mb-4">
                <span className="text-lg font-medium">Ticket Price: </span>
                <span className="text-lg">£{ticketPrice.toFixed(2)}</span>
            </div>

            <div className="mb-6">
                <span className="text-lg font-medium">Total: </span>
                <span className="text-lg">£{totalPrice.toFixed(2)}</span>
            </div>

            <div className="flex space-x-4">
                <button 
                    className="bg-prize-blue text-white p-2 rounded"
                    onClick={() => handleAddToBasket(false)}
                >
                    Add to Basket
                </button>
                <button 
                    className="bg-prize-blue text-white p-2 rounded"
                    onClick={() => handleAddToBasket(true)}
                >
                    Checkout
                </button>
            </div>
        </div>
    );
};

export default OptionSelectionPage;
