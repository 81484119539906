import React from 'react';
import { Link } from 'react-router-dom';

const RaffleError = () => {
  return (
    <div className="h-screen flex justify-center items-center bg-gray-200">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4 text-red-600">Error!</h2>
        <p className="text-lg mb-4">
          There was an issue entering the raffle(s). Please try again.
        </p>
        <Link to="/" className="text-blue-500 hover:underline">
          Go back to Home
        </Link>
      </div>
    </div>
  );
};

export default RaffleError;
