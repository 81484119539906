import React, { createContext, useState, useEffect, useContext } from 'react';

export const BasketContext = createContext();

export const useBasket = () => {
  return useContext(BasketContext);
};

export const BasketProvider = ({ children }) => {
    // Get basket from local storage
    const [basketItems, setBasketItems] = useState(() => {
        const savedItems = localStorage.getItem('basket');
        return savedItems ? JSON.parse(savedItems) : [];
      });

    const [totalAmount, setTotalAmount] = useState(0);
  
  // Saving to localStorage when basket changes
  useEffect(() => {
    try {
      localStorage.setItem('basket', JSON.stringify(basketItems));
    } catch (error) {
      console.error("Failed to save basket data to localStorage:", error);
    }
  }, [basketItems]);
  

  const addToBasket = (item) => {
    // Check if item already exists in the basket
    const existingItemIndex = basketItems.findIndex(
        (basketItem) => basketItem.id === item.id
      );
  
      if (existingItemIndex > -1) {
        // Update quantity if item already exists
        const newBasketItems = [...basketItems];
        newBasketItems[existingItemIndex].quantity = parseInt(newBasketItems[existingItemIndex].quantity, 10) + parseInt(item.quantity, 10);
        setBasketItems(newBasketItems);
      } else {
        // Add new item to basket
        setBasketItems([...basketItems, item]);
      }
  };

  const updateQuantity = (id, newQuantity) => {
    const newBasketItems = basketItems.map((item) => {
      if (item.id === id) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setBasketItems(newBasketItems);
  };

  const removeFromBasket = (id) => {
    const newBasketItems = basketItems.filter((item) => item.id !== id);
    setBasketItems(newBasketItems);
  };

  const clearBasket = () => {
    setBasketItems([]);
  };


  const checkout = () => {
    // Handle checkout logic here
    console.log('Checking out with items:', basketItems);
  }

  // Recalculate the total amount whenever the basket changes
  useEffect(() => {
    const newTotalAmount = basketItems.reduce(
      (acc, item) => acc + (item.price * item.quantity),
      0
    );
    setTotalAmount(newTotalAmount);
  }, [basketItems]);

  const value = {
    basketItems,
    addToBasket,
    removeFromBasket,
    clearBasket,
    updateQuantity,
    checkout,
    totalAmount
  };


  return (
    <BasketContext.Provider value={value}>
      {children}
    </BasketContext.Provider>
  );
};
