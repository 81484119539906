export const RaffleRating = ({ rating, size = "default" }) => {
    const MAX_RATING = 5;
    const filledTickets = Math.floor(rating);
    const partialFillPercentage = Math.round((rating - filledTickets) * 100); // Round off to an integer
  
    return (
      <div className="flex">
        {[...Array(MAX_RATING)].map((_, index) => {
          if (index < filledTickets) {
            return <RaffleTicket size={size} fill="#007bff" key={index} />;
          } else if (index === filledTickets && partialFillPercentage > 0) {
            return <RaffleTicket size={size} fill={`url(#partialFill${partialFillPercentage})`} partialFillPercentage={partialFillPercentage} key={index} />;
          } else {
            return <RaffleTicket size={size} fill="#ABB0B8" key={index} />;
          }
        })}
      </div>
    );
  };
  
  export const RaffleTicket = ({ fill, partialFillPercentage = 100, size = "default" }) => {
    const gradientId = `partialFill${partialFillPercentage}-${Math.random().toString(36).substr(2, 9)}`;
    const dimensions = size === "large" ? { width: "60", height: "50" } : { width: "30", height: "25" };
    const className = size === "large" ? "raffleRating mx-1 cursor-pointer" : "raffleRating";

    return (
      <svg {...dimensions} viewBox="0 0 640 420" className={className}>
        {/* Define the linear gradient for partial fills */}
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="0">
            <stop offset={`${partialFillPercentage}%`} stopColor="#007bff" />
            <stop offset={`${partialFillPercentage}%`} stopColor="#ABB0B8" />
          </linearGradient>
        </defs>
  
        {/* Raffle ticket shape */}
        <g id="raffleTicket" transform="matrix(7.14971 0 0 7.14971 -27.971 -141.439)" fill={fill === `url(#partialFill${partialFillPercentage})` ? `url(#${gradientId})` : fill}>
            <g id="svg_1" xmlns="http://www.w3.org/2000/svg">
                <polygon id="svg_2" points="43.954135954380035,68.34213644266129 88.57313162088394,49.85713583230972 84.19813162088394,39.29413229227066 92.20213705301285,39.29413229227066 92.20213705301285,69.15513235330582 41.99613577127457,69.15513235330582 43.53413397073746,68.52113729715347 "/>
                <polygon id="svg_3" points="29.496133863925934,38.143135130405426 15.717134535312653,43.85213476419449 15.717134535312653,38.143135130405426 "/>
            </g>
            <g id="svg_4" xmlns="http://www.w3.org/2000/svg">
                <path d="m81.65,38.14l-7.05,-17.02l-41.09,17.02l-13.78,5.71l-14.76,6.12l3.74,9.03c0.04,-0.03 0.09,-0.05 0.13,-0.07c2.64,-1.09 5.66,0.16 6.75,2.8c1.09,2.64 -0.16,5.66 -2.8,6.76c-0.05,0.01 -0.09,0.02 -0.13,0.04l3.74,9.03l23.05,-9.56l1.54,-0.63l0.41,-0.17l44.62,-18.49l-4.37,-10.57zm-48.31,26.27l1.27,-0.54l0.64,1.55l-1.27,0.54l-0.64,-1.55zm0.63,-2.09l-1.28,0.53l-0.64,-1.55l1.27,-0.54l0.65,1.56zm-1.29,-3.11l-1.27,0.54l-0.65,-1.56l1.27,-0.53l0.65,1.55zm-1.29,-3.1l-1.27,0.54l-0.64,-1.56l1.27,-0.54l0.64,1.56zm-1.28,-3.1l-1.28,0.53l-0.64,-1.55l1.27,-0.54l0.65,1.56zm-1.29,-3.11l-1.27,0.54l-0.64,-1.55l1.27,-0.54l0.64,1.55zm-1.28,-3.1l-1.27,0.53l-0.65,-1.55l1.27,-0.54l0.65,1.56zm-1.93,-4.66l0.64,1.55l-1.27,0.55l-0.64,-1.56l1.27,-0.54zm10.93,26.39l-1.27,0.53l-0.46,-1.1l-0.19,-0.45l1.27,-0.54l0.37,0.89l0.28,0.67z" id="svg_5"/>
            </g>
        </g>
      </svg>
    );
  };

export default RaffleRating;