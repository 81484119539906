import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import RatingModal from './RatingModal';

const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

function RaffleDetailComponent({ raffleDetails, entries }) {
    return (
      <div className="bg-white p-4 rounded shadow-md mb-6">
        <h2 className="text-2xl font-semibold mb-4">{raffleDetails.title}</h2>
        <img src={raffleDetails.imageUrls[0]} alt={raffleDetails.title} className="w-full h-64 object-cover rounded mb-4" />
        <h3 className="text-xl font-medium mb-2">Your Entries:</h3>
        {entries.map((entry, index) => (
          <EntryComponent key={index} raffleDetails={entry.raffleDetails} entry={entry} />
        ))}
      </div>
    );
}

function EntryComponent({ raffleDetails, entry }) {
    return (
      <div className="bg-gray-100 p-4 rounded shadow-md mb-4">
        <div className="mb-2">
          <span className="font-medium">Date Entered:</span> {new Date(entry.dateEntered).toLocaleString('en-GB')}
        </div>
        <div className="mb-2">
          <span className="font-medium">Number of Tickets:</span> {entry.quantity}
        </div>
        <div>
          <span className="font-medium">Price:</span> £{raffleDetails.ticketPrice}
        </div>
      </div>
    );
}

  

function WinnerComponent({ onConfirm, onDispute, raffleId }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    return (
      <div className="bg-green-100 p-4 mb-4 rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-green-800">Congratulations! You've won the raffle!</h2>
        <button onClick={handleOpenModal} className="bg-green-500 text-white rounded px-4 py-2 hover:bg-green-600">Confirm Receipt</button>
        <button onClick={onDispute} className="bg-yellow-500 text-white rounded px-4 py-2 hover:bg-yellow-600 ml-4">Dispute Prize</button>
        <RatingModal isOpen={isModalOpen} onClose={handleCloseModal} raffleId={raffleId} />
      </div>
    );
  }
  

function EntryDetailsPage() {
  const [entryDetails, setEntryDetails] = useState(null);
  const { raffleId } = useParams(); 

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${backendServerUrl}/api/raffle/myentries?raffleId=${raffleId}`, { withCredentials: true });
      if (response.data && response.data.length > 0) {
        setEntryDetails(response.data); // Assuming the response is an array and we're interested in the first item
      }
    }

    fetchData();
  }, [raffleId]);

  return (
    <div className="p-8">
      <h1 className="text-4xl font-semibold mb-6">Entry Details</h1>
      {entryDetails && entryDetails[0].raffleDetails.winner && <WinnerComponent raffleId={raffleId} />}
      {entryDetails && <RaffleDetailComponent raffleDetails={entryDetails[0].raffleDetails} entries={entryDetails} />}
    </div>
  );
}

export default EntryDetailsPage;
