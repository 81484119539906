import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

function CreateRaffle() {
  
  const { isLoggedIn, username, loading } = useAuth();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ticketPrice, setTicketPrice] = useState('');
  const [maxTickets, setMaxTickets] = useState('');
  const [images, setImages] = useState([]);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [isHandlingSubmit, setisHandlingSubmit] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [ticketLimitPerParticipant, setTicketLimitPerParticipant] = useState('');
  const [isNoLimitPerParticipant, setIsNoLimitPerParticipant] = useState(true);




  useEffect(() => {
    // Fetch categories from the backend
    axios.get(`${backendServerUrl}/api/raffle/categories`, { withCredentials: true })
      .then(response => {
        setCategories(response.data.categories);
      })
      .catch(error => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
    if (!loading && !isLoggedIn) {
      sessionStorage.setItem('redirectAfterLogin', '/create-raffle');
      navigate('/login');
    }
  }, [loading, isLoggedIn, navigate]);

  const handleTicketPriceChange = (e) => {
    setTicketPrice(e.target.value)
  };

  const handleTicketPriceBlur = (e) => {
    const value = parseFloat(e.target.value).toFixed(2);
    if (isNaN(value)) {
      setTicketPrice('');
    }
    else {
      setTicketPrice(value);
    }
  };

  const handleImageChange = (e) => {
    setImages([...images, ...Array.from(e.target.files)]);
  };

  const handleFreeChange = (e) => {
    setIsFree(e.target.checked);
    if (e.target.checked) {
        // Reset the ticket price value when the checkbox is checked
        setTicketPrice(''); 
    }
  };

  const handleUnlimitedChange = (e) => {
    setIsUnlimited(e.target.checked);
    if (e.target.checked) {
        // Reset the max tickets value when the checkbox is checked
        setMaxTickets('');
    }
  };



  const uploadImages = async () => {
    //console.log('images:', images); // Debug
    const formData = new FormData();
    images.forEach((image, i) => {
      formData.append('files', image);
    });
  
    try {
      console.log("Uploading: ", images);

      const response = await axios.post(`${backendServerUrl}/api/file/uploadImages`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
  
      return response.data.imageUrls;
      //setImageUrls(response.data.imageUrls);
      //console.log("Image URLs: ", imageUrls);
    } catch (error) {
      console.error("Error uploading images: ", error);
      return [];
    }
  };


  const createRaffle = async (e) => {
    e.preventDefault();
    setisHandlingSubmit(true);

    // Validation checks
  if (!title.trim()) {
    alert("Please enter a title.");
    setisHandlingSubmit(false);
    return;
  }

  if (!description.trim()) {
    alert("Please enter a description.");
    setisHandlingSubmit(false);
    return;
  }

  let category, subCategory;

  if (selectedCategory && selectedCategory !== ''){
    if(selectedCategory.includes('|')) {
      [category, subCategory] = selectedCategory.split('|');
    } else {
        category = selectedCategory;
    }
  } else {
    alert("Please select a Category.");
    setisHandlingSubmit(false);
    return;
  }

  if (images.length === 0) {
    alert("Please upload at least one image.");
    setisHandlingSubmit(false);
    return;
  }

  const now = new Date();
  const enteredEndDate = new Date(endDate);
  const minEndDate = new Date(now.getTime() + 30 * 60000); // 30 minutes from now
  if (!endDate || enteredEndDate < minEndDate) {
    alert("End date should be at least 30 minutes from now.");
    setisHandlingSubmit(false);
    return;
  }

  if (!isFree && (isNaN(parseFloat(ticketPrice)) || parseFloat(ticketPrice) <= 0)) {
    alert("Please enter a valid ticket price or select 'Free'.");
    setisHandlingSubmit(false);
    return;
  }

  if (!isUnlimited && (isNaN(parseInt(maxTickets, 10)) || parseInt(maxTickets, 10) <= 0)) {
      alert("Please enter a valid number for max tickets or select 'Unlimited'.");
      setisHandlingSubmit(false);
      return;
  }

  if (!isNoLimitPerParticipant && (isNaN(parseInt(ticketLimitPerParticipant, 10)) || parseInt(ticketLimitPerParticipant, 10) <= 0)) {
      alert("Please enter a valid number for ticket limit per person or select 'No Limit'.");
      setisHandlingSubmit(false);
      return;
  }

  // Set ticketPrice to 0 if isFree is true
  const finalTicketPrice = isFree ? 0 : ticketPrice;

  // Set maxTickets to -1 if isUnlimited is true
  const finalMaxTickets = isUnlimited ? -1 : maxTickets;

  // Set ticket limit to -1 if IsNoLimitPerParticipant is true
  const finalTicketLimitPerParticipant = isNoLimitPerParticipant ? -1 : ticketLimitPerParticipant;

  const imageUrls = await uploadImages();
  //console.log('Console Log: ', imageUrls);
  //setImageUrls(uploadedImageUrls);

  // Making a POST request to the server
  axios
    .post(`${backendServerUrl}/api/raffle/create`, {
      title,
      description,
      endDate: enteredEndDate.toISOString(),
      ticketPrice: finalTicketPrice,
      maxTickets: finalMaxTickets,
      imageUrls,
      category,
      subCategory,
      ticketLimitPerParticipant: finalTicketLimitPerParticipant,
    }, { withCredentials: true })
    .then((response) => {
      alert("Raffle created successfully!");
      setisHandlingSubmit(false);
      navigate(`/raffles/${response.data.raffleId}`)
    })
    .catch((error) => {
      setisHandlingSubmit(false);
      alert("An error occurred while creating the raffle."); // Can we make this return better text?
    });
  };

  if (loading) {
    return (
    <div className="flex justify-center items-center">
      <div className="spinner m-4"></div>
    </div> )
  }

  if (isLoggedIn){
    return (
      <div className="p-4 space-y-4">
        <h1 className="text-2xl font-semibold">Create a New Raffle</h1>
        <form className="space-y-4">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
            <input id="title" type="text" className="mt-1 p-2 w-full border rounded-md" placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
            <textarea id="description" className="mt-1 p-2 w-full h-20 border rounded-md" placeholder="Description" onChange={(e) => setDescription(e.target.value)} />
          </div>
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700">Category</label>
            <select 
              id="category" 
              className="mt-1 p-2 w-full border rounded-md"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="" disabled>Select a category</option>
              {categories && categories.map(category => (
                <React.Fragment key={category.name + "-fragment"}>
                  <option value={category.name}>{category.name}</option>
                  {category.subCategories.map(subCategory => (
                    <option key={subCategory} value={category.name + "|" + subCategory}>{"\u00A0\u00A0" + subCategory}</option>
                  ))}
                </React.Fragment>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="uploadImages" className="block text-sm font-medium text-gray-700">Upload Images</label>
          </div>
          <div className="flex overflow-x-auto">
          {images.map((image, i) => (
            <img 
              key={i}
              src={URL.createObjectURL(image)}
              alt={`Selected ${i}`} 
              className="w-1/3 h-auto" // For 3 images to fit side by side
            />
          ))}
          </div>
          <div className="mb-4">
            <label 
              htmlFor="files" 
              className="bg-blue-500 text-white px-4 py-2 cursor-pointer rounded hover:bg-blue-600 transition duration-200"
            >
              Choose Files
            </label>
            <input
              id="files"
              name="files"
              type="file"
              accept="image/*"
              multiple
              disabled={images.length >= 10}
              className="hidden"
              onChange={handleImageChange}
            />
          </div>
          <div>
            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">End Date</label>
            <input id="endDate" type="datetime-local" className="mt-1 p-2 w-full border rounded-md" onChange={(e) => setEndDate(e.target.value)} />
          </div>
          <div className="flex flex-col items-left space-y-2 sm:space-y-0 sm:space-x-2">
            <label htmlFor="ticketPrice" className="block text-sm font-medium text-gray-700">Ticket Price</label>
            
            <div className="flex items-center space-x-2 w-full sm:w-auto"> {/* Parent flex container */}
                <div className="relative rounded-md shadow-sm w-32"> {/* Ticket price input */}
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">
                            £
                        </span>
                    </div>
                    <input 
                        id="ticketPrice" 
                        type="number" 
                        value={ticketPrice}
                        className="form-input block w-full pl-7 pr-2 h-10 text-md rounded-md"
                        placeholder="0.00"
                        step="0.01"
                        onChange={handleTicketPriceChange}
                        onBlur={handleTicketPriceBlur}
                        disabled={isFree}
                    />
                </div>

                <div className="flex items-center space-x-2"> {/* Free checkbox */}
                    <input 
                        id="freeCheckbox" 
                        type="checkbox" 
                        className="form-checkbox"
                        checked={isFree}
                        onChange={handleFreeChange}
                    />
                    <label htmlFor="freeCheckbox" className="text-sm font-medium text-gray-700">Free</label>
                </div>
            </div>
          </div>
          <div className="flex flex-col items-start space-y-2 sm:space-y-0 sm:space-x-2">
            <label htmlFor="maxTickets" className="block text-sm font-medium text-gray-700">Max Tickets</label>
            <div className="flex items-center space-x-2 w-full sm:w-auto">
                <input 
                    id="maxTickets" 
                    type="number" 
                    value={maxTickets}
                    className="mt-1 p-2 w-32 border rounded-md" 
                    placeholder="Max Tickets" 
                    onChange={(e) => setMaxTickets(e.target.value)} 
                    disabled={isUnlimited}
                />
                <div className="flex items-center space-x-2">
                    <input 
                        id="unlimitedCheckbox" 
                        type="checkbox" 
                        className="form-checkbox"
                        checked={isUnlimited}
                        onChange={handleUnlimitedChange}
                    />
                    <label htmlFor="unlimitedCheckbox" className="text-sm font-medium text-gray-700">Unlimited</label>
                </div>
            </div>
          </div>
          <div className="flex flex-col items-left space-y-2 sm:space-y-0 sm:space-x-2">
            <label htmlFor="ticketLimitPerParticipant" className="block text-sm font-medium text-gray-700">Ticket Limit Per Person</label>
            
            <div className="flex items-center space-x-2 w-full sm:w-auto">
                <input 
                    id="ticketLimitPerParticipant" 
                    type="number" 
                    className="mt-1 p-2 w-32 border rounded-md" 
                    placeholder="Ticket Limit" 
                    value={ticketLimitPerParticipant}
                    onChange={(e) => setTicketLimitPerParticipant(e.target.value)}
                    disabled={isNoLimitPerParticipant}
                />

                <div className="flex items-center space-x-2">
                    <input 
                        id="noLimitCheckbox" 
                        type="checkbox" 
                        className="form-checkbox"
                        checked={isNoLimitPerParticipant}
                        onChange={(e) => {
                            setIsNoLimitPerParticipant(e.target.checked);
                            if (e.target.checked) {
                                setTicketLimitPerParticipant(''); // Reset the ticket limit value when the checkbox is checked
                            }
                        }}
                    />
                    <label htmlFor="noLimitCheckbox" className="text-sm font-medium text-gray-700">No Limit</label>
                </div>
            </div>
        </div>
          <div className="relative inline-block">
            <button
              onClick={createRaffle}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Create Raffle
            </button>
            {isHandlingSubmit && (
              <div className="absolute inset-0 flex items-center justify-center rounded bg-blue-700 text-white">
                Loading...
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
};

export default CreateRaffle;
