// SearchResults.js
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const SearchResults = () => {
    const location = useLocation();
    const [results, setResults] = useState([]);
  
    useEffect(() => {
    // Assume we get our search query from the URL, using react-router
    const searchQuery = new URLSearchParams(window.location.search).get('query');

    const fetchResults = async () => {
      try {
        const response = await axios.get(`${backendServerUrl}/api/raffle/search?query=${searchQuery}`);
        setResults(response.data);
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
      }
    };

    fetchResults();
  }, [location.search]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Search Results</h1>
      {/* Map through results and display them */}
      {results.length === 0 ? (
        <p>No results found.</p>
      ) : (
        <ul>
          {results.map((raffle, index) => (
            <li key={index} className="mb-4">
              <Link to={`/raffles/${raffle._id}`} className="text-blue-500 hover:underline">
                {raffle.title}
              </Link>
              <p>{raffle.description}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchResults;
