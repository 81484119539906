// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// Obtain environment variables
const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState(null);
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  
  // You would usually initialize this value by checking against the backend,
  // localStorage or some other method to determine the initial logged-in state
  
  useEffect(() => {
    // Initialize session from the server
    refreshUserData();
  }, []);

  const refreshUserData = () => {
    axios.get(`${backendServerUrl}/api/user/current`, { withCredentials: true })
      .then(response => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
          setUsername(response.data.username);
          setBalance(response.data.balance);
          setUserId(response.data.userId);
        }
        setLoading(false);
      })
      .catch(error => {
        setIsLoggedIn(false);
        setUsername(null);
        setLoading(false);
        setUserId(null);
      });
  };

  const logIn = (username, balance, userId) => {
    setIsLoggedIn(true);
    setUsername(username);
    setBalance(balance);
    setUserId(userId);
  };

  const logOut = () => {
    setIsLoggedIn(false);
    setUsername(null);
    setBalance(null);
    setUserId(null);
  };

  const value = {
    loading,
    userId,
    isLoggedIn,
    username,
    balance,
    refreshUserData,
    logIn,
    logOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
