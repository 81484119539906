import React, { useState } from 'react';
import { RaffleTicket } from '../misc/RaffleRating';
import axios from 'axios';

const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

function RatingModal({ isOpen, onClose, raffleId }) {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");

  const handleTicketClick = (index) => {
    setRating(index + 1);
  };

  const handleConfirmClick = async () => {
    if (rating === 0) {
      alert('Please select a rating before confirming.');
      return;
    }

    // Assuming you have the necessary data like raffleEntryId, ratedUserId, and raterUserId
    const payload = {
      raffleId,
      value: rating,
      comment
    };

    try {
      const response = await axios.post(`${backendServerUrl}/api/rating/new`, payload, { withCredentials: true });
      if (response.status === 200 || response.status === 201) {
        alert('Rating submitted successfully!');

        onClose();
      } else {
        alert('Error submitting rating. Please try again.');
      }
    } catch (error) {
      alert('Error submitting rating. Please try again.');
    }
  };
  
    return (
    isOpen && (
    <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  min-w-[200px] max-w-[90%]">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:mx-2 sm:text-left">
                  <h3 className="text-xl leading-6 font-medium text-gray-900" id="modal-title">
                    Confirm Prize Receipt
                  </h3>
                  <div className="mt-2">
                    <p className="text-md text-gray-500">
                      Please rate the raffle
                    </p>
                    <div className="mt-4 flex justify-center">
                        <div className="flex">
                        {[...Array(5)].map((_, index) => (
                            <div key={index} onClick={() => handleTicketClick(index) }  className="cursor-pointer">
                            <RaffleTicket size="large" fill={index < rating ? "#007bff" : "#ABB0B8"} />
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="mt-4">
                      <label htmlFor="comment" className="block text-md font-medium text-gray-700">Comments (optional):</label>
                      <textarea
                        id="comment"
                        name="comment"
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-md border-gray-300 rounded-md"
                        placeholder="Add your comments here..."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </div>
                    <p className="text-md text-gray-500 mt-4">
                      Only confirm if you have received the prize from the host.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleConfirmClick}
              >
                Confirm
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default RatingModal;
